import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import Signature from "./signature.json";

export const ComingSoon = () => {
  return (
    <Grid container>
      <Grid
        xs={12}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <a href="http://instagram.com/blaircaldwell">
          <Lottie animationData={Signature} loop={false} />
        </a>
        <p className="coming-soon">COMING SOON</p>
      </Grid>
    </Grid>
  );
};
